<template>
  <div class="other" >
    <div class="sub-title">请提供有关人士资料：</div>
    <van-field
      v-model.trim="form.disclosure1"
      maxlength="100"
      placeholder="请输入姓名"
      :rules="[{ required: true, message: '请输入姓名' }]"
    />
    <van-field
      v-model.trim="form.disclosure2"
      placeholder="请选择出生日期（日/月/年）"
      right-icon="arrow-down"
      readonly
      @click="showDatePickerToggle(true)"
      :rules="[{ required: true, message: '请选择出生日期（日/月/年）' }]"
    />
    <van-popup v-model:show="showDatePicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showDatePickerToggle(false)"
        @confirm="onDateConfirm"
        :min-date="minDate"
        :max-date="currDate"
      />
    </van-popup>
    <van-field
      v-model.trim="form.nation"
      placeholder="请选择国籍"
      readonly
      @click="showNationPickerToggle(true)"
      right-icon="arrow-down"
      :rules="[{ required: true, message: '请选择证件签发国家' }]"
    />
    <van-popup v-model:show="showNationPicker" round position="bottom">
      <van-picker
        title="请选择"
        :columns="nationOptions"
        @confirm="onNationConfirm"
        @cancel="showNationPickerToggle(false)"
      />
    </van-popup>
    <van-field
      v-model.trim="form.country"
      right-icon="arrow-down"
      placeholder="请选择证件签发国家"
      readonly
      @click="showCountryPickerToggle(true)"
      :rules="[{ required: true, message: '请选择证件签发国家' }]"
    />
    <van-popup v-model:show="showCountryPicker" round position="bottom">
      <van-picker
        title="请选择"
        :columns="nationOptions"
        @confirm="onIssueCountryConfirm"
        @cancel="showCountryPickerToggle(false)"
      />
    </van-popup>
    <van-field
      v-model.trim="form.disclosure4"
      placeholder="请输入身份证/护照号码"
      name="pattern"
      :rules="[{ required: true, pattern, message: '请输入正确的身份证件号码' }]"
    />
    <van-field
      v-model.trim="form.disclosure7"
      placeholder="请输入住宅地址"
      maxlength="200"
      :rules="[{ required: true, message: '请输入住宅地址' }]"
    />
    <div class="phone">
      <van-dropdown-menu>
        <van-dropdown-item v-model="form.areaCode" :options="codeOptions"/>
      </van-dropdown-menu>
      <van-field
        v-model.trim="form.phone"
        type="tel"
        name="phone"
        placeholder="请输入手机号码"
        :rules="[{ required: true, validator: validatePhone, message: '手机号格式有误' }]"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, inject, watch, ref } from 'vue'
import { useToggle } from '@vant/use'
import dayjs from 'dayjs'
import useOpenInfo from '@/hooks/openInfo'
import { HKPHONE, MACPHONE, MAINPHONE, TWNPHONE } from '@/consts/index'

export default defineComponent({
  props: {
    name: {
      type: Number,
      default: 14
    }
    // form: {
    //   type: Object,
    //   default: () => ({})
    // }
  },
  setup (props) {
    const pattern = /^[A-Za-z0-9]+$/
    const form = reactive({
      disclosureType: 2,
      disclosureCode: props.name,
      disclosureIsTrue: 0,
      disclosure1: '', // 名字
      disclosure2: '', // 出生日期
      disclosure3: '', // 国籍
      nation: '',
      disclosure5: '', // 签发国家
      country: '',
      disclosure4: '', // 证件号码
      disclosure7: '', // 证件地址
      phone: '',
      areaCode: '+86'
    })

    const currentDate = ref(new Date())
    // vuex store数据
    const { openInfo, saveOpenInfoOfVuex } = useOpenInfo()
    // 用户信息回填
    const initData = () => {
      const disclosureCode14 = openInfo.value?.disclosureCode14
      if (props.name === 14 && disclosureCode14?.disclosureIsTrue === 0) {
        Object.keys(form).forEach(val => {
          form[val] = disclosureCode14[val]
        })
      }
      const disclosureCode15 = openInfo.value?.disclosureCode15
      if (props.name === 15 && disclosureCode15?.disclosureIsTrue === 0) {
        Object.keys(form).forEach(val => {
          form[val] = disclosureCode15[val]
        })
      }
      const disclosureCode16 = openInfo.value?.disclosureCode16
      if (props.name === 16 && disclosureCode16?.disclosureIsTrue === 0) {
        Object.keys(form).forEach(val => {
          form[val] = disclosureCode16[val]
        })
      }
    }
    initData()
    const nationOptions = inject('nationOptions')
    const codeOptions = inject('codeOptions')

    // 出生日期picker
    const [showDatePicker, showDatePickerToggle] = useToggle()
    // 国籍picker
    const [showNationPicker, showNationPickerToggle] = useToggle()
    // 签发国家picker
    const [showCountryPicker, showCountryPickerToggle] = useToggle()
    const currDate = new Date()
    const onDateConfirm = date => {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      form.disclosure2 = `${year}-${month}-${day}`
      showDatePickerToggle(false)
    }
    const year = dayjs().year() - 100
    const minDate = new Date(year, 0, 1)
    const formatter = (type, val) => {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
      return val
    }
    const onNationConfirm = val => {
      form.disclosure3 = val.value
      form.nation = val.text
      showNationPickerToggle(false)
    }
    const onIssueCountryConfirm = val => {
      form.country = val.text
      form.disclosure5 = val.value
      showCountryPickerToggle(false)
    }
    const validatePhone = (val) => {
      switch (form.areaCode) {
        case '+86':
          if (!MAINPHONE.test(val)) {
            return '手机号格式不正确'
          }
          return ''
        case '+886':
          if (!TWNPHONE.test(val)) {
            return '手机号格式不正确'
          } return ''
        case '+852':
          if (!HKPHONE.test(val)) {
            return '手机号格式不正确'
          }
          return ''
        case '+853':
          if (!MACPHONE.test(val)) {
            return '手机号格式不正确'
          }
          return ''
        default:
          return ''
      }
    }
    watch(() => form, () => {
      switch (props.name) {
        case 14:
          saveOpenInfoOfVuex({ info: { disclosureCode14: form } })
          // emit('changeForm', form);
          break
        case 15:
          saveOpenInfoOfVuex({ info: { disclosureCode15: form } })
          // emit('changeForm2', form);
          break
        case 16:
          saveOpenInfoOfVuex({ info: { disclosureCode16: form } })
          // emit('changeForm2', form);
          break
        default:
          break
      }
    }, {
      deep: true,
      immediate: true
    })

    return {
      form,
      showDatePicker,
      showDatePickerToggle,
      showNationPicker,
      showNationPickerToggle,
      showCountryPicker,
      showCountryPickerToggle,
      currDate,
      onDateConfirm,
      onNationConfirm,
      onIssueCountryConfirm,
      formatter,
      minDate,
      currentDate,
      pattern,
      validatePhone,
      nationOptions,
      codeOptions
    }
  }
})
</script>

<style lang="scss" scoped>
.sub-title {
  color: $label-color;
  margin-left: var(--van-cell-horizontal-padding);
}
.other {
  .van-cell {
    padding: 0 16px;
  }
  .van-field:last-child {
    margin-bottom: 40px;
  }
}
.phone {
  display: flex;
  // align-items: center;
  .van-dropdown-menu {
    width: 20%;
    display: flex;
    align-items: center;
    height: 70px;
  }
  :deep(.van-ellipsis),:deep(.van-cell__title) {
    font-size: 14px;
  }
 :deep(.van-dropdown-menu__bar) {
    background-color: transparent;
  }
}
</style>
