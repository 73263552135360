
import { defineComponent, reactive, watch, ref, computed } from 'vue'
import { useToggle } from '@vant/use'
import { Toast } from 'vant'
import { nanoid } from 'nanoid'
import PersonInfo from './PersonInfo.vue'
import useOpenInfo from '@/hooks/openInfo'
import { ImageType, BusinessType, Source, CameraOptions } from '@/enum'
import uploadApi from '@/axios/upload'
import { getPictureAction, isInAppWebview } from '@/utils/native-app'
import { dataURLtoFile, unzip } from '@/utils/file'
import beforeUpload from '@/utils/before-upload'

export default defineComponent({
  components: { PersonInfo },
  props: {
    checked: {
      type: Array,
      default: () => []
    },
    name: {
      type: Number,
      default: 0
    },
    nationOptions: {
      type: Array,
      default: () => []
    },
    codeOptions: {
      type: Array,
      default: () => []
    },
    imagesList: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // vuex store数据
    const { openType, openInfo, saveOpenInfoOfVuex } = useOpenInfo()
    const { idKind } = openInfo.value
    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()
    // 开户同意信
    const formInfo: any = reactive({
      disclosureType: 3,
      disclosureCode: 1,
      disclosureIsTrue: 1,
      disclosure1: '',
      disclosure2: ''
    })
    const openFile: any = ref([])
    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' }
    ]

    // 图片信息回填
    const tempFile = (data: any) => {
      const list = data.filter(
        (val: { imageType: number }) => val.imageType === ImageType.ACC_IMAGE_TYPE_401
      )
      openFile.value = list.length ? [{ url: list[0].remoteUrl }] : []
    }
    tempFile(props.imagesList)
    watch(
      () => props.imagesList,
      newValue => {
        tempFile(newValue)
      }
    )

    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value || openFile.value.length) {
        return
      }
      fileActionToggle(true)
    }
    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        openFile.value = [
          {
            url: '',
            status: 'uploading',
            message: '上传中...'
          }
        ]
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_401,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_7,
          idKindType: openInfo.value.idKind,
          kindSource: openType.value,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            openFile.value = [
              {
                url: openAccountUploadFile.data,
                status: 'done'
              }
            ]
          } else {
            console.log('上传失败')
          }
        })
      })
    }

    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }

    const onFileSelect = async (item: { key: string }) => {
      const url = await getPictureAction(item.key)
      if (url) {
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }

    // 中央编号
    const formInfo2: any = reactive({
      disclosureIsTrue: 1,
      disclosureType: 3,
      disclosureCode: 2,
      disclosure1: ''
    })
    // 金马雇员/亲属 人物关系
    const formInfo3: any = reactive({
      disclosureType: 3,
      disclosureCode: 3,
      disclosureIsTrue: 1,
      disclosure1: '',
      disclosure2: ''
    })
    // 有关公司资料
    const formInfo4: any = reactive({
      disclosureType: 3,
      disclosureCode: 4,
      disclosureIsTrue: 1,
      disclosure1: '',
      disclosure2: ''
    })
    // 政治人物
    const formInfo5: any = reactive({
      disclosureType: 3,
      disclosureCode: 5,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    // 犯罪记录
    const formInfo6: any = reactive({
      disclosureType: 3,
      disclosureCode: 6,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    // 其他请注明
    const formInfo7: any = reactive({
      disclosureType: 3,
      disclosureCode: 7,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    // 其他请注明
    const formInfo8: any = reactive({
      disclosureType: 3,
      disclosureCode: 8,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    const formInfo27: any = reactive({
      disclosureType: 3,
      disclosureCode: 27,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    const formInfo24: any = reactive({
      disclosureType: 3,
      disclosureCode: 24,
      disclosureIsTrue: 1,
      disclosure1: ''
    })
    const formInfo29: any = reactive({
      disclosureType: 3,
      disclosureCode: 29,
      disclosureIsTrue: 1,
      disclosure1: '',
      disclosure2: ''
    })
    // 回填
    const initData = () => {
      const disclosureCode1 = openInfo.value?.disclosureCode1
        ? openInfo.value?.disclosureCode1
        : formInfo
      Object.keys(disclosureCode1).forEach(val => {
        formInfo[val] = disclosureCode1[val]
      })
      const disclosureCode2 = openInfo.value?.disclosureCode2
        ? openInfo.value?.disclosureCode2
        : formInfo2
      Object.keys(disclosureCode2).forEach(val => {
        formInfo2[val] = disclosureCode2[val]
      })
      const disclosureCode3 = openInfo.value?.disclosureCode3
        ? openInfo.value?.disclosureCode3
        : formInfo3
      Object.keys(disclosureCode3).forEach(val => {
        formInfo3[val] = disclosureCode3[val]
      })
      const disclosureCode4 = openInfo.value?.disclosureCode4
        ? openInfo.value?.disclosureCode4
        : formInfo4
      Object.keys(disclosureCode4).forEach(val => {
        formInfo4[val] = disclosureCode4[val]
      })
      const disclosureCode5 = openInfo.value?.disclosureCode5
        ? openInfo.value?.disclosureCode5
        : formInfo5
      Object.keys(disclosureCode5).forEach(val => {
        formInfo5[val] = disclosureCode5[val]
      })
      const disclosureCode6 = openInfo.value?.disclosureCode6
        ? openInfo.value?.disclosureCode6
        : formInfo6
      Object.keys(disclosureCode6).forEach(val => {
        formInfo6[val] = disclosureCode6[val]
      })
      const disclosureCode7 = openInfo.value?.disclosureCode7
        ? openInfo.value?.disclosureCode7
        : formInfo7
      Object.keys(disclosureCode7).forEach(val => {
        formInfo7[val] = disclosureCode7[val]
      })
      const disclosureCode8 = openInfo.value?.disclosureCode8
        ? openInfo.value?.disclosureCode8
        : formInfo8
      Object.keys(disclosureCode8).forEach(val => {
        formInfo8[val] = disclosureCode8[val]
      })
      const disclosureCode27 = openInfo.value?.disclosureCode27
        ? openInfo.value?.disclosureCode27
        : formInfo27
      Object.keys(disclosureCode27).forEach(val => {
        formInfo27[val] = disclosureCode27[val]
      })
      const disclosureCode24 = openInfo.value?.disclosureCode24
        ? openInfo.value?.disclosureCode24
        : formInfo24
      if (idKind !== 2 && idKind !== 3) {
        Object.keys(disclosureCode24).forEach(val => {
          formInfo24[val] = disclosureCode24[val]
        })
      }
      const disclosureCode29 = openInfo.value?.disclosureCode29
        ? openInfo.value?.disclosureCode29
        : formInfo29
      Object.keys(disclosureCode29).forEach(val => {
        formInfo29[val] = disclosureCode29[val]
      })
    }
    initData()
    const onCode1Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode1: formInfo } })
    }
    const onCode2Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode2: formInfo2 } })
    }
    const onCode3Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode3: formInfo3 } })
    }
    const onCode4Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode4: formInfo4 } })
    }
    const onCode5Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode5: formInfo5 } })
    }
    const onCode6Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode6: formInfo6 } })
    }
    const onCode7Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode7: formInfo7 } })
    }
    const onCode8Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode8: formInfo8 } })
    }
    const onCode24Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode24: formInfo24 } })
    }
    const onCode27Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode27: formInfo27 } })
    }
    const onCode29Blur = () => {
      saveOpenInfoOfVuex({ info: { disclosureCode29: formInfo29 } })
    }

    const onOversize = () => {
      Toast('文件大小不能超过 10M')
    }

    return {
      onOversize,
      openFile,
      formInfo,
      onCode1Blur,
      formInfo2,
      onCode2Blur,
      formInfo3,
      onCode3Blur,
      formInfo4,
      onCode4Blur,
      formInfo5,
      onCode5Blur,
      formInfo6,
      onCode6Blur,
      formInfo7,
      onCode7Blur,
      formInfo8,
      onCode8Blur,
      formInfo27,
      onCode27Blur,
      formInfo24,
      onCode24Blur,
      formInfo29,
      onCode29Blur,
      beforeRead,
      uploadToApp,
      isApp,
      fileActionState,
      actionsList,
      onFileSelect,
      idKind
    }
  }
})
